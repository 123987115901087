<template>
  <pt-pt-login
    class="pt-pt-login__withData"
    ref="form"
    @getCode="getCode"
    @loginSubmit="loginSubmit"
  ></pt-pt-login>
</template>

<script>
import PtPtLogin from "./index";
import {hasPermissionsOfTrust} from "formats/permissions.js"
export default {
  name: "pt-pt-login__withData",
  components: {
    "pt-pt-login": PtPtLogin
  },
  methods: {
    loginSubmit (form) {
      let data = {
        ...form,
        type: "mobile",
        rememberMe: true
      };
      if(this.$refs.form.isPassword){
        this.$delete(data, 'type')
        this.$delete(data, 'mobile')
        this.$delete(data, 'authorizationCode')
      }else{
        data.type = "mobile",
        this.$delete(data, 'username')
        this.$delete(data, 'password')
      }
      this.$store
        .dispatch("login", data)
        .then((res) => {
          this.$toast.success("登录成功");
          this.getUserInformation()
        })
        .catch((err) => {
          this.$toast.error(err || "登录失败");
        });
    },
    getUserInformation (){
      this.$store.dispatch('getUserInformation').then(res => {
        this.$nextTick(_ => {
          if(hasPermissionsOfTrust()){
          	this.$router.push('/Home')
            return
          }
          this.$router.push('/task-order')
        })
      }).catch(err => {
        this.$toast(err || '获取用户信息失败')
      })
    },
    getCode (mobile, getCodeVM) {
      let data = {
        mobilePhone: mobile,
        businessKey: "login"
      };
      this.$store
        .dispatch("getCode", data)
        .then((res) => {
          if (res.data && res.data.message) {
            this.$toast.info(res.data.message);
          }
          // 自动聚焦到验证码输入框
          this.$refs.form.autofocusAuthorizationCode = true;
        })
        .catch((errorMessage) => {
          console.log("errorMessage", errorMessage);
          this.$toast.error(errorMessage);
          getCodeVM.reset();
        });
    }
  }
};
</script>

<template>
  <pt-pt-login class="pt-pt-login__page"></pt-pt-login>
</template>

<script>
import PtPtLogin from "cps//pt-login/withData";
export default {
  name: "pt-pt-login__view",
  components: {
    "pt-pt-login": PtPtLogin
  }
};
</script>

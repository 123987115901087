<template>
  <div :class="$style[classes]">
    <div class="logo-title">
      <img class="lihua-logo-class" src="@/assets/logo.png" />
      <div class="title">
        <h2>理化中心</h2>
        <h2>材料化学部</h2>
      </div>
    </div>
    <validate-form tag="div" ref="form">
      <template v-if="isPassword">
        <pt-form-item
          class="form-item"
          tag="div"
          name="手机号码"
          rules="required"
          ref="username"
          :showError="false"
        >
          <wt-input
            :autofocus="true"
            v-model="formValue.username"
            placeholder="请输入手机号/用户名"
          >
          </wt-input>
        </pt-form-item>
        <pt-form-item
          class="form-item"
          tag="div"
          rules="required"
          name="密码"
          ref="password"
          :showError="false"
        >
          <wt-input v-model="formValue.password" placeholder="请输入密码" type="password" :eye="{open:false}">
          </wt-input>
        </pt-form-item>
      </template>
      <template v-else>
				<validate-form  ref="phone">
        <pt-form-item
          class="form-item"
          tag="div"
          name="手机号码"
          rules="required|phone"
          ref="mobile"
          :showError="false"
        >
          <wt-input
            :autofocus="true"
            type="number"
            v-model="formValue.mobile"
            :maxLength="11"
          placeholder="请输入手机号"
          >
          </wt-input>
        </pt-form-item>
				</validate-form>
        <pt-form-item
          class="form-item"
          tag="div"
          rules="required"
          name="验证码"
          ref="authorizationCode"
        >
          <wt-input
            :autofocus="autofocusAuthorizationCode"
            type="number"
            v-model="formValue.authorizationCode"
            placeholder="请输入验证码"
          >
            <wt-count-down-box
              slot="rt"
              ref="countDownBox"
              :class="$style.getValidCode"
              @on-start="startGetCode"
              >获取验证码</wt-count-down-box
            >
          </wt-input>
        </pt-form-item>
      </template>
    </validate-form>
    <cube-button class="login-button" :primary="true" @click="loginSubmit"
      >登 录</cube-button
    >
    <div class="switch-login-type">
      <span @click="switchLoginType">{{ loginTypeText }}</span>
    </div>
  </div>
</template>

<script>
// 组件class前缀
const prefixCls = "pt-login";
import formMixins from 'mixins/form'
export default {
  mixins: [formMixins],
  name: "pt-login",
  computed: {
    classes () {
      return `${prefixCls}`;
    },
    countDownBox () {
      return this.$refs.countDownBox;
    },
    authorizationCode () {
      return this.$refs.authorizationCode;
    },
    isPassword () {
      return this.formType === "password";
    },
    loginTypeText () {
      return this.formType === "password" ? "短信登录" : "密码登录";
    }
  },
  data () {
    return {
      formValue: {},
      autofocusAuthorizationCode: false,
      formType: "note"
      // authorizationCode:null
    };
  },
  methods: {
    switchLoginType () {
      this.formType = this.formType === "note" ? "password" : "note";
    },
    loginSubmit () {
      this.$refs.form.validate("formValue").then((res) => {
        this.$emit("loginSubmit", this.formValue);
      });
    },
    startGetCode (next) {
      this.$nextTick((_) => {
        this.validate(null, 'phone').then(res => {
          next();
          this.$emit("getCode", this.formValue.mobile, this.countDownBox);
        }).catch(err => {
          this.$nextTick((_) => {
            this.countDownBox.reset();
          });
          this.$toast.error(err || "手机号码为空或格式错误");
        })
      });
    }
  }
};
</script>

<style module lang="less">
/* 已启用css module， template中请使用$style.className添加class */
/* 务必遵循css BEM原则 */
@import "~less/var.less";
.pt-login {
	padding: 40px 40px 0;
	background-color: #fff;
	height: 100%;
	box-sizing: border-box;
  :global {
    .form-item {
      padding: 10px 0;
      .wt-count-down-box {
        color: @main-color!important;
        // font-size: 13px;
        border-left: solid 1px #adadad;
      }
    }
    .pt-wt-input {
      background-color: #f6f6f6;
      border-radius: 20px;
    }
    .logo-title {
      display: flex;
      padding: 20px 10px 0;
      flex-direction: column;
    }
    .title {
      padding: 20px 0;
      h2 {
        padding: 5px 0;
				text-align: start;
      }
    }
    .lihua-logo-class {
      width: 100px;
    }
    .login-button {
      border-radius: 30px;
			margin-top: 10px;
			width: 100%;
    }
    .switch-login-type {
      color: @font-of-grey;
      padding: 20px 0;
      font-size: 14px;
    }
  }
}
.getValidCode {
}
.getValidCode {
  min-width: 6em;
  min-height: auto;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-left: 10px;
}
</style>
